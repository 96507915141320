import Landing from "./views/landing/index.js";
import { clarity } from 'react-microsoft-clarity';

function App() {
    clarity.init('lc1b69lew7');
    return (
        // <BrowserRouter basename="/">
        //     <Routes>
        //         <Route path="/" element={<Landing />} />
        //         <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        //     </Routes>
        // </BrowserRouter>
        <Landing/>
        // <PrivacyPolicy />
    );
}

export default App;
