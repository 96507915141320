import React from "react";
import Checkbox from "../../../assets/img/checkbox.svg";
import { LinkStyle } from "../../footers/LandingFooter";


import "./style.css";

export const Cta = () => {
  return (
    <div className="frame">
      <div className="div">
        <div className="div-2">
          <div className="get-started-for-free">
            Get Started
            <br />
            for free
          </div>
          <p className="transforming-blank">
            Transforming Blank Pages into Captivating Narratives
            <br />
            DocuHelp AI empowers businesses to discover their distinctive storytelling style
          </p>
        </div>
        <div className="div-3">
          <LinkStyle href="/welcome" rel="noopener noreferrer">

            <div className="div-wrapper">
              <div className="text-wrapper">Get Started</div>
            </div>
          </LinkStyle>
          <LinkStyle href="/login" rel="noopener noreferrer">

            <div className="div-wrapper-2">
              <div className="text-wrapper-2">Sign In</div>
            </div>
          </LinkStyle>
        </div>
      </div>
      <div className="div-4">
        <div className="div-5">
          <img className="group" alt="Vector" src={require("../../../assets/img/group-52650.png")} />

          <div className="text-wrapper-3">Try Docuhelp Now!</div>
        </div>
        <div className="div-6">
          <div className="div-5">
            <div className="checkbox-circle-fill">
              <img className="vector" alt="Vector" src={Checkbox} />
            </div>
            <div className="text-wrapper-4">No credit card required</div>
          </div>
          <div className="div-5">
            <div className="checkbox-circle-fill">
              <img className="vector" alt="Vector" src={Checkbox} />
            </div>
            <div className="text-wrapper-4">Unlimited access</div>
          </div>
        </div>
      </div>
    </div>
  );
};
